<!-- src/views/attributes/attributes.vue -->
<template>
  <v-container fluid data-cy="container" style="padding-top: 0px">
    <v-card class="pa-3" style="border: none" data-cy="main-card">
      <v-tabs v-model="tab" style="border-bottom: 2px solid" data-cy="tabs">
        <v-tab :key="0" data-cy="tab-overview" active>Overview</v-tab>

        <v-spacer></v-spacer>
        <v-select
          class="mr-3 filter-select"
          max-width="200"
          v-model="tableFilter"
          :items="tableFilterData"
          variant="underlined"
          data-cy="form-value-mode"
          prepend-inner-icon="mdi-filter"
        ></v-select>
        <v-text-field
          class="mr-3"
          max-width="200"
          v-model="search"
          density="compact"
          label="Search"
          prepend-inner-icon="mdi-magnify"
          variant="underlined"
          hide-details
          single-line
          data-cy="search-field"
        ></v-text-field>
        <v-btn class="btn-orange" @click="openModal('new')" data-cy="new-button">New</v-btn>
      </v-tabs>

      <div v-if="tab === 0" data-cy="tab-content-overview">
        <v-data-table
          :headers="headers"
          :items="filteredItems"
          class="custom-table"
          data-cy="data-table"
          loading-text="Loading... Please wait"
          :loading="loadingData"
          v-model:items-per-page="itemsPerPage"
          :items-per-page-options="itemsPerPageOptions"
        >
          <template v-slot:item.name="{ item }">
            <n-ellipsis style="max-width: 240px">{{ item.name }}</n-ellipsis>
          </template>

          <template v-slot:item.description="{ item }">
            <n-ellipsis style="max-width: 240px">{{ item.description }}</n-ellipsis>
          </template>

          <template v-slot:item.unit="{ item }">
            {{ getUomNameById(item.uomId) }}
          </template>

          <template v-slot:item.alias="{ item }">
            <n-ellipsis style="max-width: 240px">{{ item.alias }}</n-ellipsis>
          </template>

          <template v-slot:item.values="{ item }">
            <v-chip size="x-small" v-for="(value, index) in item.values" :key="index" color="primary" class="mr-2" data-cy="value-chip">
              <n-ellipsis style="max-width: 36px">{{ value.value }}</n-ellipsis>
            </v-chip>
          </template>
          <template v-slot:item.category="{ item }">
            <span>{{ item.category?.join(', ') }}</span>
          </template>
          <template v-slot:item.actions="{ item }">
            <div class="controls-wrapper">
              <span @click="handleCopy(item.id)">
                <v-tooltip activator="parent" location="top">Copy GUID</v-tooltip>
                <i class="fa-duotone fa-solid fa-copy"></i>
              </span>
              <span @click="openModal('edit', item)"><i class="fa-duotone fa-solid fa-pen-to-square"></i></span>
              <span @click="confirmDelete(item)"><i class="fa-duotone fa-solid fa-trash"></i></span>
            </div>
          </template>
        </v-data-table>
      </div>

      <div v-else data-cy="tab-content-details">
        <div class="text-center" data-cy="details-content">
          <h2 data-cy="details-title">Details</h2>
          <p data-cy="details-description">Information on Construction</p>
        </div>
      </div>

      <!-- New/Edit Modal -->
      <v-dialog v-model="modal" max-width="1100px" data-cy="modal" persistent class="custom-dialog">
        <v-card data-cy="modal-card">
          <v-card-title class="modal-title" data-cy="modal-title">
            <span class="text-h5">{{ modalTitle }}</span>
            <v-icon small @click="handleCancel" data-cy="close-icon" style="right: 10px; top: 10px; position: absolute">mdi-close</v-icon>
          </v-card-title>
          <v-card-text data-cy="modal-content">
            <v-row>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="formData.name"
                  label="Name"
                  variant="underlined"
                  data-cy="form-name"
                  :error-messages="nameError"
                  :rules="[
                    (v) => v.length <= 64 || 'Max 64 characters',
                    (v) => /^[a-zA-Z0-9\s_-]*$/.test(v) || 'Invalid characters, only letters, numbers, spaces, hyphens, and underscores are allowed',
                  ]"
                ></v-text-field>
                <v-text-field
                  v-model="formData.description"
                  label="Description"
                  variant="underlined"
                  data-cy="form-description"
                  :error-messages="descriptionError"
                  :rules="[
                    (v) => v.length <= 128 || 'Max 128 characters',
                    (v) => /^[a-zA-Z0-9\s_-]*$/.test(v) || 'Invalid characters, only letters, numbers, spaces, hyphens, and underscores are allowed',
                  ]"
                ></v-text-field>

                <v-select
                  v-model="selectedValueMode"
                  :items="filteredValueOptions"
                  label="Value Mode"
                  variant="underlined"
                  data-cy="form-value-mode"
                  :error-messages="valueModeError"
                ></v-select>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="formData.alias"
                  label="Alias"
                  variant="underlined"
                  data-cy="form-alias"
                  :error-messages="aliasError"
                  :rules="[
                    (v) => v.length <= 64 || 'Max 64 characters',
                    (v) => /^[a-zA-Z0-9\s_-]*$/.test(v) || 'Invalid characters, only letters, numbers, spaces, hyphens, and underscores are allowed',
                  ]"
                ></v-text-field>
                <v-select :items="genUnitOptions" v-model="formData.uomId" return-object clearable data-cy="form-unit" variant="underlined">
                  <template #item="data">
                    <v-list-subheader v-if="data.props && data.props.header" class="uom-select-header">
                      <b>{{ data.props.header }}</b>
                    </v-list-subheader>
                    <v-divider v-else-if="data.props && data.props.divider" />
                    <v-list-item v-else v-bind="data.props"></v-list-item>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-container style="margin: 0px; padding: 0px">
              <div v-if="selectedValueMode === 'formula'" data-cy="formula-mode">
                <v-toolbar flat>
                  <v-toolbar-title>Formula</v-toolbar-title>
                  <v-spacer></v-spacer>
                </v-toolbar>
                <v-text-field v-model="formData.formula" variant="underlined" label="Formula" data-cy="form-formula"></v-text-field>
              </div>
              <div v-if="selectedValueMode === 'enum'" data-cy="enum-mode">
                <v-data-table :headers="enumHeaders" :items="filteredEnumValues" :items-per-page="5" class="elevation-1" data-cy="enum-table">
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-toolbar-title>Enum Values</v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-text-field
                        v-model="enumSearch"
                        label="Search"
                        single-line
                        hide-details
                        data-cy="enum-search"
                        density="compact"
                        prepend-inner-icon="mdi-magnify"
                        variant="underlined"
                        style="max-width: 200px; margin-right: 10px"
                      ></v-text-field>
                      <v-btn class="btn-orange mr-3" @click="addEnumValue" :disabled="!canAddEnumValue" data-cy="add-enum-button">
                        <v-icon left>mdi-plus</v-icon>
                        Add
                      </v-btn>
                    </v-toolbar>
                  </template>

                  <template v-slot:item="{ item, index }">
                    <tr>
                      <td class="no-border">
                        <v-text-field v-model="(item as Value).code" variant="underlined" data-cy="enum-code-input" class="enum-text-field" single-line hide-details></v-text-field>
                      </td>
                      <td class="no-border">
                        <v-text-field
                          v-model="(item as Value).value"
                          variant="underlined"
                          data-cy="enum-value-input"
                          :error-messages="enumValueErrors[index]"
                          single-line
                          hide-details
                        ></v-text-field>
                      </td>
                      <td class="no-border">
                        <v-text-field v-model="(item as Value).description" variant="underlined" data-cy="enum-description-input" single-line hide-details></v-text-field>
                      </td>
                      <td class="no-border text-center">
                        <v-icon small color="red" @click="removeEnumValue(index)" data-cy="enum-remove-icon">mdi-delete</v-icon>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </div>

              <div v-if="selectedValueMode === 'range' && isUnitSelected" data-cy="range-mode">
                <v-toolbar flat>
                  <v-toolbar-title>Range</v-toolbar-title>
                  <v-spacer></v-spacer>
                </v-toolbar>
                <v-text-field
                  v-model.number="formData.range.min"
                  variant="underlined"
                  label="Min Value"
                  data-cy="form-range-min"
                  :error-messages="rangeMinError"
                  :rules="[isPositiveNumber]"
                  type="number"
                ></v-text-field>
                <v-text-field
                  v-model.number="formData.range.max"
                  variant="underlined"
                  label="Max Value"
                  data-cy="form-range-max"
                  :error-messages="rangeMaxError"
                  :rules="[isPositiveNumber]"
                  type="number"
                ></v-text-field>
              </div>
            </v-container>
          </v-card-text>

          <v-card-actions data-cy="modal-actions" class="btn-modal-bottom">
            <v-spacer></v-spacer>
            <v-btn class="btn-cancel" @click="handleCancel" data-cy="cancel-button">Cancel</v-btn>
            <v-btn class="btn-save" @click="saveAttribute" data-cy="save-button">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <Dialogs
        :cancelDialog="cancelDialog"
        :deleteDialog="deleteDialog"
        @closeCancelDialog="closeCancelDialog"
        @confirmCancel="confirmCancel"
        @closeDeleteDialog="closeDeleteDialog"
        @confirmDeleteItem="confirmDeleteItem"
      />
    </v-card>
  </v-container>
</template>

<script lang="ts">
  import { defineComponent, ref, computed, onMounted } from 'vue';
  import { copyToClipboard } from '@/services/clipboard';
  import Dialogs from '@/components/Dialogs.vue';
  import { Item, Value, Range, Unit, UnitCategory, EnumValue } from '@/types/attributes';
  import lodash, { filter } from 'lodash';
  import { useUserPreferencesStore } from '@/store/userPreferencesStore';
  import attributeService from '@/services/api/attributeService';
  import etcService from '@/services/api/etcService';

  export default defineComponent({
    name: 'AttributesComponent',
    components: {
      Dialogs,
    },
    data() {
      return {
        tab: 0,
        search: '',
        modal: false,
        deleteDialog: false,
        cancelDialog: false,
        modalTitle: '',
        itemToDelete: null as Item | null,
        selectedValueMode: '',
        formData: this.resetForm(),
        originalFormData: {} as Item,
        valueOptions: [
          { title: 'Enum', value: 'enum' },
          { title: 'Range', value: 'range' },
        ],
        unitOptions: [] as UnitCategory[],
        headers: [
          { title: 'Name', value: 'name', sortable: true, width: '20%', key: 'name', dataCy: 'name-header' },
          { title: 'Description', value: 'description', sortable: true, width: '15%', key: 'description' },
          { title: 'Unit', value: 'unit', sortable: true, width: '5%', key: 'unit' },
          { title: 'Alias', value: 'alias', sortable: true, width: '20%', key: 'alias' },
          { title: 'Values', value: 'values', sortable: false, width: '20%' },
          { title: 'Category', value: 'category', sortable: true, width: '10%', key: 'category' },
          { title: 'Actions', value: 'actions', sortable: false, width: '10%' },
        ],
        enumHeaders: [
          { title: 'Code', value: 'code', sortable: true, width: '20%' },
          { title: 'Value', value: 'value', sortable: true, width: '20%' },
          { title: 'Description', value: 'description', sortable: true, width: '34%' },
          { title: 'Actions', value: 'actions', sortable: false, width: '6%' },
        ],
        items: [] as Item[],
        errorMessage: '',
        loadingData: false,
        nameError: '',
        aliasError: '',
        descriptionError: '',
        valueModeError: '',
        enumValueError: '',
        rangeMinError: '',
        rangeMaxError: '',
        enumValueErrors: [] as string[],
        UoMCategory: [] as any[],
        UoMs: [] as any[],
        tableFilter: 'All',
        tableFilterData: ['All', 'Logic', 'Raw material', 'Finished good'],
        itemsPerPage: 10,
        itemsPerPageOptions: [10, 25, 50, 100, -1],
        enumSearch: '',
      };
    },
    computed: {
      filteredItems() {
        let filtered = this.items;

        if (this.tableFilter !== 'All') {
          filtered = filtered.filter((item) => item.category?.includes(this.tableFilter));
        }

        if (this.search) {
          filtered = filtered.filter((item) =>
            Object.keys(item).some((key) =>
              String((item as any)[key])
                .toLowerCase()
                .includes(this.search.toLowerCase())
            )
          );
        }
        return filtered;
      },
      filteredEnumValues() {
        if (!this.enumSearch) {
          return this.formData.values;
        }

        return this.formData.values.filter((value: EnumValue) =>
          ['code', 'value', 'description'].some((key) =>
            String(value[key as keyof EnumValue])
              .toLowerCase()
              .includes(this.enumSearch.toLowerCase())
          )
        );
      },
      canAddEnumValue() {
        const values = this.formData.values;
        const lastValue = values![values.length - 1];
        return values!.length === 0 || (lastValue && lastValue.code !== '');
      },
      hasChanges() {
        return !lodash.isEqual(this.formData, this.originalFormData);
      },
      isUnitSelected() {
        return this.formData.uomId && this.formData.uomId !== '';
      },
      filteredValueOptions() {
        return this.isUnitSelected ? this.valueOptions : this.valueOptions.filter((option) => option.value !== 'range');
      },
      genUnitOptions() {
        const options: any[] = [];

        this.unitOptions.forEach((category: UnitCategory) => {
          if (category.name) {
            options.push({ props: { header: category.name } });
          }

          if (Array.isArray(category.value)) {
            category.value.forEach((unit: Unit) => {
              options.push({
                title: `${unit.name} (${unit.alias})`, // Display unit name with alias
                value: unit,
                props: { value: unit },
              });
            });
          }

          options.push({ props: { divider: true } });
        });

        if (options.length > 0 && options[options.length - 1].props.divider) {
          options.pop();
        }

        return options;
      },
    },
    watch: {
      'formData.name'(newValue: string) {
        if (newValue.trim()) {
          this.nameError = '';
          this.aliasError = '';
          this.descriptionError = '';
        }
      },
      selectedValueMode(newValue: string) {
        if (newValue) {
          this.valueModeError = '';
          if (this.modalTitle === 'Create Attribute') {
            this.addEnumValue();
          }
        }
      },
      'formData.values': {
        handler(newValues: EnumValue[]) {
          newValues.forEach((item, index) => {
            if (item.value.trim()) {
              this.enumValueErrors[index] = '';
            }
          });
        },
        deep: true,
      },
      'formData.range.min'(newValue) {
        this.validateRange();
      },
      'formData.range.max'(newValue) {
        this.validateRange();
      },
      tableFilter(newFilter) {
        this.saveUserPreferences({ tableFilter: newFilter });
      },
      itemsPerPage(newItemsPerPage) {
        this.saveUserPreferences({ itemsPerPage: newItemsPerPage });
      },
    },
    mounted() {
      this.getAttributesList();
      this.getUnits();
      this.restoreUserPreferences();
    },
    methods: {
      openModal(action: 'new' | 'edit', item: Item | null = null) {
        this.modalTitle = action === 'new' ? 'Create Attribute' : 'Edit Attribute';

        if (item) {
          this.formData = Object.assign(this.resetForm(), item);
          this.selectedValueMode = this.getSelectedValueMode(item);
          this.setUomToText();
          this.originalFormData = lodash.cloneDeep(this.formData);
        } else {
          this.formData = this.resetForm();
          this.originalFormData = lodash.cloneDeep(this.formData);
          this.selectedValueMode = '';
        }

        this.modal = true;
      },

      setUomToText() {
        if (typeof this.formData.uomId === 'string') {
          let matchedUnit: Unit | undefined;

          for (const category of this.unitOptions) {
            if (category.value) {
              matchedUnit = category.value.find((unit: Unit) => unit.id === this.formData.uomId);
              if (matchedUnit) break;
            }
          }

          if (matchedUnit) {
            this.formData.uomId = matchedUnit;
          } else {
            this.formData.uomId = null;
          }
        }
      },

      getSelectedValueMode(item: Item) {
        if (item.values && item.values.length) return 'enum';
        if (item.formula && item.formula.length) return 'formula';
        if (item.range && item.range) return 'range';
        return '';
      },
      removeEnumValue(index: number) {
        this.formData.values!.splice(index, 1);
      },
      handleCancel() {
        if (this.hasChanges) {
          this.cancelDialog = true;
        } else {
          this.closeModal();
        }
      },
      closeModal() {
        this.modal = false;
      },
      async saveAttribute() {
        const isValid = this.validation();
        if (!isValid) {
          return;
        }
        this.prepareFormData();
        try {
          let toast_text = '';
          if (!this.formData.id) {
            toast_text = 'Successfully added';
            await this.createAttribute(toast_text);
          } else {
            toast_text = 'Successfully updated';
            await this.updateAttribute(this.formData.id, toast_text);
          }
          this.setUomToText();
          this.getAttributesList();
        } catch (error: any) {
          this.$error.view(error);
        }
      },
      prepareFormData() {
        const { selectedValueMode, formData } = this;
        if (!formData.id) {
          delete formData.id;
        }
        const uidTemp = formData.uomId;

        formData.uomId =
          uidTemp && typeof uidTemp === 'object' && 'value' in uidTemp && uidTemp.value.id
            ? uidTemp.value.id
            : uidTemp && typeof uidTemp === 'object' && 'id' in uidTemp
              ? uidTemp.id
              : undefined;

        if (selectedValueMode === 'enum') {
          formData.range = undefined;
          formData.formula = undefined;
          formData.values = formData.values
            .map((value: any) => {
              if (value.code === '') value.code = undefined;
              if (value.description === '') value.description = undefined;
              return value;
            })
            .filter((value: any) => Object.keys(value).some((key) => value[key] !== undefined));
        } else if (selectedValueMode === 'range' && this.isUnitSelected) {
          formData.values = undefined;
          formData.formula = undefined;
        } else if (selectedValueMode === 'formula') {
          formData.values = undefined;
          formData.range = undefined;
        } else {
          formData.values = undefined;
          formData.range = undefined;
          formData.formula = undefined;
        }
      },
      confirmDelete(item: Item) {
        this.itemToDelete = item;
        this.deleteDialog = true;
      },
      closeDeleteDialog() {
        this.deleteDialog = false;
        this.itemToDelete = null;
      },
      async confirmDeleteItem() {
        if (this.itemToDelete) {
          await this.deleteAttribute(this.itemToDelete.id!);
          this.items = this.items.filter((i) => i.id !== this.itemToDelete!.id);
          this.closeDeleteDialog();
        }
      },
      confirmCancel() {
        this.getAttributesList();
        this.closeModal();
        this.cancelDialog = false;
      },
      closeCancelDialog() {
        this.cancelDialog = false;
      },
      addEnumValue() {
        if (this.canAddEnumValue) {
          this.formData.values!.unshift({
            code: '',
            value: '',
            description: '',
          });
        }
      },
      validation() {
        let isValid = true;

        const allowedCharsRegex = /^[a-zA-Z0-9\s_-]*$/;

        if (!this.formData.name.trim()) {
          this.nameError = 'Name cannot be empty';
          isValid = false;
        } else if (this.formData.name.length > 64) {
          this.nameError = 'Name cannot exceed 64 characters';
          isValid = false;
        } else if (!allowedCharsRegex.test(this.formData.name)) {
          this.nameError = 'Invalid characters in Name';
          isValid = false;
        } else {
          this.nameError = '';
        }

        if (this.formData.description.length > 128) {
          this.descriptionError = 'Description cannot exceed 128 characters';
          isValid = false;
        } else if (!allowedCharsRegex.test(this.formData.description)) {
          this.descriptionError = 'Invalid characters in Description';
          isValid = false;
        } else {
          this.descriptionError = '';
        }

        if (this.formData.alias.length > 64) {
          this.aliasError = 'Alias cannot exceed 64 characters';
          isValid = false;
        } else if (!allowedCharsRegex.test(this.formData.alias)) {
          this.aliasError = 'Invalid characters in Alias';
          isValid = false;
        } else {
          this.aliasError = '';
        }

        if (this.selectedValueMode === 'range') {
          isValid = isValid && this.validateRange();
        } else {
          this.rangeMinError = '';
          this.rangeMaxError = '';
        }

        return isValid;
      },
      validateRange() {
        let isValid = true;

        if (this.formData.range && typeof this.formData.range.min !== 'undefined' && typeof this.formData.range.max !== 'undefined') {
          const min = parseFloat(this.formData.range.min);
          const max = parseFloat(this.formData.range.max);

          if (!isNaN(min) && !isNaN(max) && min > max) {
            this.rangeMinError = 'Minimum value cannot be greater than the maximum';
            isValid = false;
          } else {
            this.rangeMinError = '';
            this.rangeMaxError = '';
          }
        } else {
          this.rangeMinError = '';
          this.rangeMaxError = '';
          isValid = false;
        }

        return isValid;
      },
      isPositiveNumber(value: number) {
        return (!!value && value > 0) || 'Value must be greater than 0';
      },
      async getUnits() {
        try {
          this.unitOptions = await etcService.getUnits();
        } catch (error: any) {
          this.$error.view(error);
        }
      },
      async getAttributesList() {
        this.loadingData = true;
        try {
          this.items = await attributeService.getAttributesList();
          this.loadingData = false;
        } catch (error: any) {
          this.loadingData = false;
          this.$error.view(error);
        }
      },

      async createAttribute(toast_text: string) {
        try {
          const newItem = await attributeService.createAttribute(this.formData);
          this.items.push(newItem);
          this.$swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'success',
            title: toast_text,
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.closeModal();
        } catch (error: any) {
          this.$error.view(error);
        }
      },
      async updateAttribute(id: string, toast_text: string) {
        try {
          const updatedItem = await attributeService.updateAttribute(id, this.formData);
          const index = this.items.findIndex((item) => item.id === id);
          if (index !== -1) this.items.splice(index, 1, updatedItem);
          this.$swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'success',
            title: toast_text,
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.closeModal();
        } catch (error: any) {
          this.$error.view(error);
        }
      },

      async deleteAttribute(id: string) {
        try {
          await attributeService.deleteAttribute(id);
          this.items = this.items.filter((item) => item.id !== id);
          this.$swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'success',
            title: 'Successfully deleted',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        } catch (error: any) {
          this.$error.view(error);
        }
      },
      getUomNameById(uomId: any) {
        for (const category of this.unitOptions) {
          const unit = category.value?.find((u) => u.id === uomId);
          if (unit) {
            return unit.name;
          }
        }
        return '';
      },
      resetForm(): Item {
        return {
          name: '',
          uomId: '',
          description: '',
          alias: '',
          values: [],
          range: { min: 0, max: 0 },
          formula: '',
        };
      },

      async handleCopy(id: any) {
        try {
          await copyToClipboard(id, this.$swal);
        } catch (error) {
          console.error('Copy operation failed:', error);
        }
      },
      saveUserPreferences(preferences: { tableFilter?: string; itemsPerPage?: number }) {
        const userPreferencesStore = useUserPreferencesStore();
        if (preferences.tableFilter !== undefined) {
          userPreferencesStore.setAttributesTableFilterStore(preferences.tableFilter);
        }
        if (preferences.itemsPerPage !== undefined) {
          userPreferencesStore.setAttributesTableItemsPerPage(preferences.itemsPerPage);
        }
      },

      restoreUserPreferences() {
        const userPreferencesStore = useUserPreferencesStore();
        this.tableFilter = userPreferencesStore.pageTableFilters.attributesTableFilterStore;
        this.itemsPerPage = userPreferencesStore.itemsPerPage.attributesTableItemsPerPage;
      },
    },
  });
</script>

<style scoped>
  .v-data-table {
    margin-top: 20px;
  }

  .modal-title {
    background-color: #3b516b;
    color: white;
  }

  .enum-table {
    border: none !important;
    padding: 0px 4px !important;
  }

  .enum-text-field .v-input__control {
    margin: 0;
    padding: 0;
    background-color: #f5f5f5;
    border-radius: 4px;
  }

  .enum-text-field .v-input__slot {
    padding: 4px 8px;
  }

  .enum-text-field input {
    color: #000;
    font-size: 14px !important;
    padding: 4px 8px !important;
    border: none;
  }

  .custom-table .v-data-table__wrapper {
    overflow-x: auto;
  }

  .custom-table th,
  .custom-table td {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .custom-table {
    overflow-x: auto;
  }

  .custom-table th,
  .custom-table td {
    max-width: 20%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
  }

  .custom-table .v-data-table__wrapper {
    overflow-x: auto;
  }

  .custom-table .v-chip {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .uom-select-header {
    background-color: #f5f5f5;
    color: #000;
    font-weight: bold;
  }

  .controls-wrapper {
    display: flex;
    justify-content: space-around;
    font-size: 18px;
  }
  .controls-wrapper span {
    cursor: pointer;
  }
  .controls-wrapper span > i:hover {
    color: orange;
  }
  .controls-wrapper span > i.fa-pen-to-square:hover {
    color: rgb(64, 64, 194);
  }
  .controls-wrapper span > i.fa-trash:hover {
    color: red;
  }

  .filter-select {
    margin: -9px 0px 0px 0px;
  }
  :deep(.v-table thead) {
    background-color: #f5f5f5;
  }
  ::v-deep(.v-data-table thead) {
    background-color: #f5f5f5;
  }
  ::v-deep(.v-data-table tr:hover) {
    background-color: #f7f7f7;
  }
</style>
