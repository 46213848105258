// src/main.ts
import { createApp } from 'vue';
import App from '@/App.vue';
import router from '@/router';
import pinia from '@/store';
import vuetify from '@/plugins/vuetify';
import logger from './services/logger';
import error from './services/errors';
import { createAuth0 } from '@auth0/auth0-vue';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import naive from 'naive-ui';
// css styles
import '@/assets/global.css';

// Font Awesome
import '@/assets/fontawesome/css/all.css';

const app = createApp(App);
const domain = import.meta.env.VITE_AUTH0_DOMAIN;
const clientId = import.meta.env.VITE_AUTH0_CLIENT_ID;
const audience = import.meta.env.VITE_AUTH0_AUDIENCE;
const redirectUri = window.location.origin;
// const organization = import.meta.env.VITE_AUTH0_ORGANIZATION;

app.config.globalProperties.$log = logger;
app.config.globalProperties.$error = error;

app.use(router);
app.use(pinia);
app.use(vuetify);
app.use(naive);
app.use(VueSweetalert2);
app.use(
  createAuth0({
    domain,
    clientId,
    authorizationParams: {
      redirect_uri: redirectUri,
      audience: audience,
      // organization: organization,
    },
    // useRefreshTokens: true,
    // cacheLocation: 'localstorage',
    // useRefreshTokensFallback: false,
  })
);
app.mount('#app');
