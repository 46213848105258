<!-- src/components/Dialogs.vue -->
<template>
  <!-- Cancel Confirmation Dialog -->
  <v-dialog :model-value="cancelDialog" @update:model-value="onCloseCancelDialog" max-width="400px" data-cy="cancel-dialog">
    <v-card data-cy="cancel-dialog-card">
      <v-card-title class="headline" data-cy="cancel-dialog-title">Confirm Cancel</v-card-title>
      <v-card-text data-cy="cancel-dialog-text">All changes will be lost. Do you want to continue?</v-card-text>
      <v-card-actions data-cy="cancel-dialog-actions" class="btn-modal-bottom">
        <v-spacer></v-spacer>
        <v-btn class="btn-cancel" @click="onCloseCancelDialog" data-cy="cancel-no-button">No</v-btn>
        <v-btn class="btn-delete" @click="onConfirmCancel" data-cy="cancel-yes-button">Yes</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <!-- Delete Confirmation Modal -->
  <v-dialog :model-value="deleteDialog" @update:model-value="onCloseDeleteDialog" max-width="400px" data-cy="delete-dialog">
    <v-card data-cy="delete-dialog-card">
      <v-card-title class="headline" data-cy="delete-dialog-title">Confirm Delete</v-card-title>
      <v-card-text data-cy="delete-dialog-text">
        Are you sure you want to
        <strong>delete</strong>
        this item?
        <br />
        This operation can’t be undone.
      </v-card-text>
      <v-card-actions data-cy="delete-dialog-actions" class="btn-modal-bottom">
        <v-spacer></v-spacer>
        <v-btn class="btn-cancel" @click="onCloseDeleteDialog" data-cy="delete-cancel-button">Cancel</v-btn>
        <v-btn class="btn-delete" @click="onConfirmDeleteItem" data-cy="delete-confirm-button">Delete</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <!-- Revoke Confirmation Dialog -->
  <v-dialog :model-value="revokeDialog" @update:model-value="onCloseRevokeDialog" max-width="400px" data-cy="revoke-dialog">
    <v-card data-cy="revoke-dialog-card">
      <v-card-title class="headline" data-cy="revoke-dialog-title">Confirm Revoke</v-card-title>
      <v-card-text data-cy="revoke-dialog-text">
        Are you sure you want to revoke the certification for this product number?
        <br />
        This operation can’t be undone.
      </v-card-text>
      <v-card-actions data-cy="revoke-dialog-actions" class="btn-modal-bottom">
        <v-spacer></v-spacer>
        <v-btn class="btn-cancel" @click="onCloseRevokeDialog" data-cy="revoke-cancel-button">Cancel</v-btn>
        <v-btn class="btn-delete" @click="onConfirmRevoke" data-cy="revoke-confirm-button">Revoke</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- Update Confirmation Dialog -->
  <v-dialog :model-value="updateDialog" @update:model-value="onCloseUpdateDialog" max-width="400px">
    <v-card>
      <v-card-title class="headline">Confirm Update</v-card-title>
      <v-card-text>Are you sure you want to update the selected BOMs?</v-card-text>
      <v-card-actions class="btn-modal-bottom">
        <v-spacer></v-spacer>
        <v-btn class="btn-cancel" @click="onCloseUpdateDialog">Cancel</v-btn>
        <v-btn class="btn-orange" @click="$emit('confirmCancel')">Confirm</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  export default {
    props: {
      cancelDialog: {
        type: Boolean,
        required: false,
        default: undefined,
      },
      deleteDialog: {
        type: Boolean,
        required: false,
        default: undefined,
      },
      revokeDialog: {
        type: Boolean,
        required: false,
        default: undefined,
      },
      updateDialog: {
        type: Boolean,
        required: false,
        default: undefined,
      },
    },
    emits: [
      'closeCancelDialog',
      'confirmCancel',
      'closeDeleteDialog',
      'confirmDeleteItem',
      'closeRevokeDialog',
      'confirmRevoke',
      'update:cancelDialog',
      'update:deleteDialog',
      'update:revokeDialog',
      'update:updateDialog',
    ],
    methods: {
      onCloseCancelDialog() {
        this.$emit('update:cancelDialog', false);
        this.$emit('closeCancelDialog');
      },
      onConfirmCancel() {
        this.$emit('confirmCancel');
      },
      onCloseDeleteDialog() {
        this.$emit('update:deleteDialog', false);
        this.$emit('closeDeleteDialog');
      },
      onConfirmDeleteItem() {
        this.$emit('confirmDeleteItem');
      },
      onCloseRevokeDialog() {
        this.$emit('update:revokeDialog', false);
        this.$emit('closeRevokeDialog');
      },
      onConfirmRevoke() {
        this.$emit('confirmRevoke');
      },
      onCloseUpdateDialog() {
        this.$emit('update:updateDialog', false);
      },
    },
  };
</script>

<style scoped>
  .btn-modal-bottom {
    display: flex;
    justify-content: space-between;
  }
</style>
