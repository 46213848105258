<template>
  <svg :viewBox="`0 0 ${VIEWBOX_WIDTH} ${canvasHeight}`" :width="VIEWBOX_WIDTH" :height="canvasHeight" style="background: #f5f5f5; border: 1px solid #dddddd">
    <!-- LEFT SIDE -->
    <g :class="`connector-${connector.id}`" v-for="connector in leftConnectorData" :key="`left-connector-${connector.id}`">
      <rect :x="LEFT_MARGIN" :y="connector.y" :width="CONNECTOR_WIDTH" :height="connector.height" fill="#ddd" stroke="#666" />
      <text
        :class="`connector-${connector.id}-text`"
        text-anchor="middle"
        alignment-baseline="middle"
        :transform="`translate(${LEFT_MARGIN - 15}, ${connector.y + connector.height / 2}) rotate(-90)`"
        fill="#333"
      >
        Conn {{ connector.id }}
      </text>

      <g v-for="(fiber, idx) in connector.fiberPositions" :key="`left-fiber-${connector.id}-${fiber.number}`">
        <circle
          :cx="LEFT_MARGIN + CONNECTOR_WIDTH / 2"
          :cy="getFiberY(connector.y, idx, connector.fiberPositions.length)"
          r="3"
          :fill="fiber.isMissing ? 'none' : '#333'"
          :stroke="fiber.isMissing ? '#333' : 'none'"
          stroke-width="1"
        />
        <text
          :x="LEFT_MARGIN + CONNECTOR_WIDTH / 2 - 25"
          :y="getFiberY(connector.y, idx, connector.fiberPositions.length)"
          alignment-baseline="middle"
          fill="#000"
          :class="{ 'highlighted-text': isFiberHighlighted(connector.id, fiber.number, 'left') }"
        >
          {{ fiber.number }}
        </text>
      </g>
    </g>

    <!-- RIGHT SIDE -->
    <g v-for="connector in rightConnectorData" :key="`right-connector-${connector.id}`">
      <rect :x="VIEWBOX_WIDTH - RIGHT_MARGIN - CONNECTOR_WIDTH" :y="connector.y" :width="CONNECTOR_WIDTH" :height="connector.height" fill="#ddd" stroke="#666" />
      <text
        text-anchor="middle"
        alignment-baseline="middle"
        :transform="`translate(${VIEWBOX_WIDTH - RIGHT_MARGIN + 15}, ${connector.y + connector.height / 2}) rotate(90)`"
        fill="#333"
      >
        Conn {{ connector.id }}
      </text>

      <g v-for="(fiber, idx) in connector.fiberPositions" :key="`right-fiber-${connector.id}-${fiber.number}`">
        <circle
          :cx="VIEWBOX_WIDTH - RIGHT_MARGIN - CONNECTOR_WIDTH / 2"
          :cy="getFiberY(connector.y, idx, connector.fiberPositions.length)"
          r="3"
          :fill="fiber.isMissing ? 'none' : '#333'"
          :stroke="fiber.isMissing ? '#333' : 'none'"
          stroke-width="1"
        />
        <text
          :x="VIEWBOX_WIDTH - RIGHT_MARGIN - CONNECTOR_WIDTH / 2 + 25"
          :y="getFiberY(connector.y, idx, connector.fiberPositions.length)"
          text-anchor="end"
          alignment-baseline="middle"
          fill="#000"
          :class="{ 'highlighted-text': isFiberHighlighted(connector.id, fiber.number, 'right') }"
        >
          {{ fiber.number }}
        </text>
      </g>
    </g>
    <!-- PATCHES -->
    <path
      v-for="(item, idx) in items"
      :key="`connection-${idx}`"
      :d="
        generatePath(
          getFiberX('left', item.connectorA, item.fiberAPosition),
          getFiberYByData(item.connectorA, item.fiberAPosition, 'left'),
          getFiberX('right', item.connectorB, item.fiberBPosition),
          getFiberYByData(item.connectorB, item.fiberBPosition, 'right')
        )
      "
      :stroke="getColorValue(item.color || '')"
      fill="none"
      stroke-width="3"
      @mouseenter="highlightPath(idx)"
      @mouseleave="unhighlightPath(idx)"
      :class="{ 'highlighted-path': highlightedPath === idx || isSelected(item) }"
    />
  </svg>
</template>

<script lang="ts">
  import { defineComponent, computed, PropType, ref } from 'vue';

  interface PolarityItem {
    fiber: number;
    color?: string;
    connectorA: number;
    fiberAPosition: number;
    connectorB: number;
    fiberBPosition: number;
  }

  interface FiberPositionData {
    number: number;
    isMissing: boolean;
  }

  interface ConnectorData {
    id: number;
    fiberPositions: FiberPositionData[];
    height: number;
    y: number;
  }

  export default defineComponent({
    name: 'FiberConnectionsVisualizer',
    props: {
      items: {
        type: Array as PropType<PolarityItem[]>,
        required: true,
      },
      selectedRow: {
        type: Object as PropType<PolarityItem | null>,
        default: null,
      },
    },

    setup(props) {
      const VIEWBOX_WIDTH = 359;
      const CONNECTOR_WIDTH = 70;
      const LEFT_MARGIN = 60;
      const RIGHT_MARGIN = 60;
      const CONNECTOR_SPACING = 20;
      const TOP_BOTTOM_PADDING = 40;
      const distanceBetweenFibers = 27;
      const INITIAL_TOP = 20;

      const colors: { [key: string]: string } = {
        Black: '#000000',
        Red: '#FF0000',
        White: '#FFFFFF',
        Slate: '#708090',
        Brown: '#A52A2A',
        Green: '#008000',
        Orange: '#FFA500',
        Blue: '#0000FF',
      };

      const highlightedPath = ref<number | null>(null);

      function isSelected(item: PolarityItem) {
        return props.selectedRow && props.selectedRow.fiber === item.fiber && props.selectedRow.connectorA === item.connectorA && props.selectedRow.connectorB === item.connectorB;
      }
      // Highlight path on mouse enter
      function highlightPath(index: number) {
        // highlightedPath.value = index;
      }

      // Unhighlight path on mouse leave
      function unhighlightPath(index: number) {
        // if (highlightedPath.value === index) {
        //   highlightedPath.value = null;
        // }
      }

      function isFiberHighlighted(connectorId: number, fiberNumber: number, side: 'left' | 'right'): boolean {
        if (highlightedPath.value !== null) {
          const item = props.items[highlightedPath.value];
          if (item) {
            if (side === 'left') {
              if (item.connectorA === connectorId && item.fiberAPosition === fiberNumber) return true;
            } else {
              if (item.connectorB === connectorId && item.fiberBPosition === fiberNumber) return true;
            }
          }
        }

        if (props.selectedRow) {
          if (side === 'left') {
            if (props.selectedRow.connectorA === connectorId && props.selectedRow.fiberAPosition === fiberNumber) return true;
          } else {
            if (props.selectedRow.connectorB === connectorId && props.selectedRow.fiberBPosition === fiberNumber) return true;
          }
        }

        return false;
      }

      // Get UNIQUE connectors from items
      const leftConnectorsRaw = computed(() => Array.from(new Set(props.items.map((item) => item.connectorA))).sort((a, b) => a - b));
      const rightConnectorsRaw = computed(() => Array.from(new Set(props.items.map((item) => item.connectorB))).sort((a, b) => a - b));

      // Get fiber positions with missing fibers
      function getFiberPositionsWithMissing(existingPositions: number[]): FiberPositionData[] {
        if (existingPositions.length === 0) return [];

        const minPos = Math.min(...existingPositions);
        const maxPos = Math.max(...existingPositions);

        const result: FiberPositionData[] = [];
        for (let num = minPos; num <= maxPos; num++) {
          result.push({
            number: num,
            isMissing: !existingPositions.includes(num),
          });
        }
        return result;
      }

      // Build connector data
      function buildConnectorData(ids: number[], side: 'left' | 'right'): ConnectorData[] {
        let currentY = INITIAL_TOP;
        const result: ConnectorData[] = [];

        for (const id of ids) {
          // Real fibers
          const relevantItems = side === 'left' ? props.items.filter((i) => i.connectorA === id) : props.items.filter((i) => i.connectorB === id);

          // Get unique positions
          const rawPositions = Array.from(new Set(relevantItems.map((item) => (side === 'left' ? item.fiberAPosition : item.fiberBPosition)))).sort((a, b) => a - b);

          // Get fiber positions with missing fibers
          const fiberPositions = getFiberPositionsWithMissing(rawPositions);
          // Calculate height
          const fiberCount = fiberPositions.length;
          let height = TOP_BOTTOM_PADDING;
          if (fiberCount > 1) {
            height += (fiberCount - 1) * distanceBetweenFibers;
          }

          // Add to result
          const connectorData: ConnectorData = {
            id,
            fiberPositions,
            height,
            y: currentY,
          };
          result.push(connectorData);

          // Update Y
          currentY += height + CONNECTOR_SPACING;
        }

        return result;
      }

      const leftConnectorData = computed<ConnectorData[]>(() => buildConnectorData(leftConnectorsRaw.value, 'left'));
      const rightConnectorData = computed<ConnectorData[]>(() => buildConnectorData(rightConnectorsRaw.value, 'right'));

      // Calculate canvas height
      const canvasHeight = computed(() => {
        const leftMax = leftConnectorData.value.length
          ? leftConnectorData.value[leftConnectorData.value.length - 1].y + leftConnectorData.value[leftConnectorData.value.length - 1].height
          : 100;
        const rightMax = rightConnectorData.value.length
          ? rightConnectorData.value[rightConnectorData.value.length - 1].y + rightConnectorData.value[rightConnectorData.value.length - 1].height
          : 100;
        return Math.max(leftMax, rightMax) + 50;
      });

      // Y coordinate for fiber
      function getFiberY(baseY: number, fiberIdx: number, fiberCount: number): number {
        const startY = baseY + TOP_BOTTOM_PADDING / 2;
        return startY + fiberIdx * distanceBetweenFibers;
      }

      // Y coordinate for fiber by connector ID and fiber position
      function getFiberYByData(connectorId: number, fiberPos: number, side: 'left' | 'right'): number {
        const dataList = side === 'left' ? leftConnectorData.value : rightConnectorData.value;
        const cData = dataList.find((c) => c.id === connectorId);
        if (!cData) {
          return 0;
        }
        // Find index of fiber position
        const idx = cData.fiberPositions.findIndex((fp) => fp.number === fiberPos);
        if (idx < 0) {
          return 0;
        }
        return getFiberY(cData.y, idx, cData.fiberPositions.length);
      }

      // Generate path for connection
      function generatePath(x1: number, y1: number, x2: number, y2: number): string {
        const cx = (x1 + x2) / 2;
        return `M ${x1} ${y1} C ${cx} ${y1}, ${cx} ${y2}, ${x2} ${y2}`;
      }

      // X coordinate for fiber
      function getFiberX(side: 'left' | 'right', connectorId: number, fiberPos: number): number {
        return side === 'left' ? LEFT_MARGIN + CONNECTOR_WIDTH / 2 : VIEWBOX_WIDTH - RIGHT_MARGIN - CONNECTOR_WIDTH / 2;
      }

      // Get color value
      function getColorValue(colorName: string) {
        return colors[colorName] || '#000000';
      }

      return {
        items: props.items,

        // const
        VIEWBOX_WIDTH,
        canvasHeight,
        LEFT_MARGIN,
        RIGHT_MARGIN,
        CONNECTOR_WIDTH,
        CONNECTOR_SPACING,
        distanceBetweenFibers,
        TOP_BOTTOM_PADDING,

        // data
        leftConnectorData,
        rightConnectorData,
        highlightedPath,
        isSelected,
        // functions
        getFiberY,
        getFiberYByData,
        getFiberX,
        generatePath,
        getColorValue,
        highlightPath,
        unhighlightPath,
        isFiberHighlighted,
      };
    },
  });
</script>

<style scoped>
  .highlighted-path {
    stroke-width: 6px !important;
    stroke-opacity: 0.8;
    cursor: pointer;
    filter: drop-shadow(0 0 2px #555);
  }
  .highlighted-text {
    font-weight: bold;
    font-size: 18px;
    fill: #000000;
  }
  circle {
    transition: fill 0.2s;
  }
</style>
