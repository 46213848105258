<template>
  <v-dialog v-model="showPolarityModal" max-width="1200px" persistent>
    <v-card>
      <v-card-title class="modal-title">
        <span class="text-h5">Polarity</span>
        <v-icon small @click="close" class="close-icon">mdi-close</v-icon>
      </v-card-title>

      <v-container>
        <v-row>
          <v-col md="8">
            <div class="text-start">
              <p class="title-label">
                Polarity Method:
                <span>{{ polarityData?.patternInfo?.pattern }}</span>
              </p>
            </div>
            <v-data-table
              :headers="headers"
              :items="items"
              class="elevation-1"
              item-value="fiber"
              density="comfortable"
              items-per-page="-1"
              :items-per-page-options="itemsPerPageOptions"
              @mouseenter:row="onRowHover"
              @mouseleave:row="onRowUnHover"
            >
              <template #top>
                <div class="custom-header">
                  <span class="group-header" style="grid-column: span 2">Trunk</span>
                  <span class="group-header" style="grid-column: span 2">END A</span>
                  <span class="group-header" style="grid-column: span 2">END B</span>
                </div>
              </template>
              <template #item.color="{ item }">
                <div :style="{ display: 'flex', alignItems: 'center', border: `1px solid ${getColorValue(item.color || '')}`, padding: '5px', borderRadius: '50px' }">
                  <div class="color-circle me-2" :style="{ backgroundColor: getColorValue(item.color || '') }"></div>
                  <span>{{ getColorLabel(item.color || '') }}</span>
                </div>
              </template>
            </v-data-table>
          </v-col>
          <v-col md="4">
            <v-container>
              <v-row>
                <div class="text-start">
                  <p class="title-label">Connection scheme:</p>
                </div>
                <FiberConnectionsVisualizer v-if="showDraw" :items="items" :selectedRow="selectedRow" style="margin-top: -4px" />
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-container>

      <v-card-actions class="btn-modal-bottom">
        <input type="file" accept=".xlsx, .xls" ref="fileInput" @change="handleFileUpload" style="display: none" />
        <v-btn @click="exportData" class="btn-cancel ml-4">Export</v-btn>
        <v-btn @click="triggerFileInput" class="btn-cancel">Import</v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="close" class="btn-cancel">Cancel</v-btn>
        <v-btn disabled @click="save" class="btn-save">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { defineComponent, PropType, watch } from 'vue';
  import _ from 'lodash';
  import * as XLSX from 'xlsx';
  import FiberConnectionsVisualizer from './PolarityDraw.vue';

  interface PolarityItem {
    fiber: number;
    color?: string;
    connectorA: number;
    fiberAPosition: number;
    connectorB: number;
    fiberBPosition: number;
  }

  export default defineComponent({
    components: {
      FiberConnectionsVisualizer,
    },
    props: {
      showPolarityModal: {
        type: Boolean,
        required: true,
      },
      polarityData: {
        type: Object as any,
        required: true,
      },
    },
    emits: ['close', 'save'],

    watch: {
      showPolarityModal: {
        immediate: true,
        handler(val) {
          if (val) {
            this.items = this.polarityData.polarityData as PolarityItem[];
          }
        },
      },
    },

    data() {
      return {
        headers: [
          { title: 'Fiber #', value: 'fiber', align: 'end' as const },
          { title: 'Position Color', value: 'color', align: 'start' as const },
          { title: 'Connection', value: 'connectorA', align: 'end' as const },
          { title: 'Fiber Position', value: 'fiberAPosition', align: 'end' as const },
          { title: 'Connection', value: 'connectorB', align: 'end' as const },
          { title: 'Fiber Position', value: 'fiberBPosition', align: 'end' as const },
        ],

        items: this.polarityData.polarityData as PolarityItem[],
        colors: [
          { label: 'Black', value: '#000000' },
          { label: 'Red', value: '#FF0000' },
          { label: 'White', value: '#f5f5f5' },
          { label: 'Slate', value: '#708090' },
          { label: 'Brown', value: '#A52A2A' },
          { label: 'Green', value: '#008000' },
          { label: 'Orange', value: '#FFA500' },
          { label: 'Blue', value: '#0000FF' },
        ],
        itemsPerPageOptions: [10, 25, 50, 100, -1],
        selectedRow: null as PolarityItem | null,
        showDraw: true,
      };
    },

    // onMounted() {
    //   this.items = this.polarityData;
    // },
    methods: {
      onRowHover(event: Event, row: any) {
        const item = row.item;
        this.selectedRow = item as PolarityItem;
      },
      onRowUnHover() {
        this.selectedRow = null;
      },
      getColorValue(colorName: string) {
        const colorItem = this.colors.find((c) => c.label === colorName);
        return colorItem ? colorItem.value : colorName;
      },
      getColorLabel(colorValue: string) {
        const colorItem = this.colors.find((c) => c.value === colorValue);
        return colorItem ? colorItem.label : colorValue;
      },
      validateRow(row: any): PolarityItem | null {
        const fiber = parseInt(row[0]);
        const color = String(row[1]);
        const connectorA = parseInt(row[2]);
        const fiberAPosition = parseInt(row[3]);
        const connectorB = parseInt(row[4]);
        const fiberBPosition = parseInt(row[5]);

        if (!isNaN(fiber) && color && !isNaN(connectorA) && !isNaN(fiberAPosition) && !isNaN(connectorB) && !isNaN(fiberBPosition)) {
          return {
            fiber,
            color,
            connectorA,
            fiberAPosition,
            connectorB,
            fiberBPosition,
          };
        }
        return null;
      },

      handleFileUpload(event: Event) {
        this.showDraw = false;
        const input = event.target as HTMLInputElement;
        const file = input?.files?.[0];
        if (!file) return;

        const validTypes = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'];
        if (!validTypes.includes(file.type)) {
          alert('Please upload a valid Excel file (.xlsx or .xls)');
          return;
        }

        const reader = new FileReader();
        reader.onload = (e: ProgressEvent<FileReader>) => {
          try {
            const data = new Uint8Array(e.target?.result as ArrayBuffer);
            const workbook = XLSX.read(data, { type: 'array' });
            const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
            const jsonData = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });

            const validItems: PolarityItem[] = [];
            for (let i = 1; i < jsonData.length; i++) {
              const row = jsonData[i];
              const validatedRow = this.validateRow(row);
              if (validatedRow) {
                validItems.push(validatedRow);
              }
            }

            if (validItems.length === 0) {
              alert('No valid data found in the uploaded file');
              return;
            }

            this.items = validItems;
            this.showDraw = true;
          } catch (error) {
            console.error('Error processing file:', error);
            alert('Error processing the file. Please check the file format and try again.');
          }
        };

        reader.onerror = () => {
          alert('Error reading the file');
        };

        reader.readAsArrayBuffer(file);
        input.value = '';
      },

      exportData() {
        try {
          const headers = ['Fiber #', 'Position Color', 'END A Connection', 'END A Fiber Position', 'END B Connection', 'END B Fiber Position'];

          const data = this.items.map((item: PolarityItem) => [
            item.fiber,
            this.getColorLabel(item.color || ''),
            item.connectorA,
            item.fiberAPosition,
            item.connectorB,
            item.fiberBPosition,
          ]);

          const worksheet = XLSX.utils.aoa_to_sheet([headers, ...data]);

          // Add column widths
          const colWidths = [10, 20, 20, 20, 20, 20];
          worksheet['!cols'] = colWidths.map((width) => ({ width }));

          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, 'Polarity Data');

          const date = new Date().toISOString().split('T')[0];
          XLSX.writeFile(workbook, `polarity_data_${date}.xlsx`);
        } catch (error) {
          console.error('Error exporting data:', error);
          alert('Error exporting data. Please try again.');
        }
      },
      triggerFileInput() {
        const fileInput = this.$refs.fileInput as HTMLInputElement;
        if (fileInput) {
          fileInput.click();
        }
      },
      close() {
        this.$emit('close');
      },
      save() {
        this.$emit('save');
      },
    },
  });
</script>

<style scoped>
  .modal-title {
    background-color: #3b516b;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .close-icon {
    cursor: pointer;
  }

  .custom-header {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    text-align: center;
    font-weight: bold;
    background-color: #f5f5f5;
    padding: 10px 0;
  }

  .group-header {
    font-size: 14px;
  }

  .color-select-container {
    width: 100%;
    min-width: 150px;
  }

  .color-select {
    width: 100%;
  }

  .color-circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid #ddd;
    display: inline-block;
  }
  /* :deep(.v-table thead) {
    background-color: #f5f5f5;
  }
  ::v-deep(.v-data-table thead) {
    background-color: #f5f5f5;
  } */
  ::v-deep(.v-data-table tr:hover) {
    background-color: #f7f7f7;
  }

  .title-label {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: -6px;
  }
</style>
